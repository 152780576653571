import React from "react"
import TransitionLinkFade from "../TransitionLink/TransitionLinkFade"
import PropTypes from "prop-types"

import { LINK_TYPE_ENTRY } from "../../constants"

const FlyoutMenuLink = ({ title, url, type, newWindow }) => {
  if (type !== LINK_TYPE_ENTRY) {
    /*eslint-disable*/
    return (
      <a
        className="FlyoutMenuLink"
        target={newWindow ? "_blank" : null}
        rel={newWindow ? "noopener noreferrer" : null}
        href={url}
      >
        {title}
      </a>
    )
    /*eslint-enable*/
  } else {
    return (
      <TransitionLinkFade
        className="FlyoutMenuLink"
        activeClassName="FlyoutMenuLink--active"
        to={`/${url}`}
      >
        {title}
      </TransitionLinkFade>
    )
  }
}

FlyoutMenuLink.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string,
  type: PropTypes.string,
  newWindow: PropTypes.bool,
}

export default FlyoutMenuLink

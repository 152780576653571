import React, { useState } from "react"
import TransitionLinkFade from "../TransitionLink/TransitionLinkFade"
import PropTypes from "prop-types"
import { CSSTransition } from "react-transition-group"

import HeaderSubmenu from "./HeaderSubmenu"
import { LINK_TYPE_ENTRY } from "../../constants"

const Submenu = ({ items, showSubmenu }) => (
  <CSSTransition
    in={showSubmenu}
    timeout={300}
    classNames="scale-fade"
    unmountOnExit
  >
    <HeaderSubmenu submenuItems={items} />
  </CSSTransition>
)

const HeaderMenuLink = ({ type, title, url, newWindow, children }) => {
  const [showSubmenu, setShowSubmenu] = useState(false)
  function handleMouseEnter() {
    setShowSubmenu(true)
  }
  function handleMouseLeave() {
    setShowSubmenu(false)
  }
  function handleClick() {
    setShowSubmenu(false)
  }

  const hasSubmenu = children?.length > 0
  const linkClass = hasSubmenu
    ? "HeaderMenuLink"
    : "HeaderMenuLink HeaderMenuLink--has-underline"
  if (type !== LINK_TYPE_ENTRY) {
    return (
      <li
        className="HeaderMenuLinkWrapper"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onFocus={e => {
          if (!e.currentTarget.contains(e.relatedTarget)) {
            handleMouseEnter()
          }
        }}
        onBlur={e => {
          if (!e.currentTarget.contains(e.relatedTarget)) {
            handleMouseLeave()
          }
        }}
      >
        {/*eslint-disable*/}
        <a
          target={newWindow ? "_blank" : null}
          rel={newWindow ? "noopener noreferrer" : null}
          href={url}
          className={linkClass}
        >
          {title}
        </a>
        {/*eslint-enable*/}
        {hasSubmenu && <Submenu items={children} showSubmenu={showSubmenu} />}
      </li>
    )
  } else {
    return (
      <li
        className="HeaderMenuLinkWrapper"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onFocus={e => {
          if (!e.currentTarget.contains(e.relatedTarget)) {
            handleMouseEnter()
          }
        }}
        onClick={() => {
          handleClick()
        }}
        onBlur={e => {
          if (!e.currentTarget.contains(e.relatedTarget)) {
            handleMouseLeave()
          }
        }}
      >
        <TransitionLinkFade
          className={linkClass}
          activeClassName="HeaderMenuLink--active"
          to={`/${url}`}
        >
          {title}
        </TransitionLinkFade>
        {hasSubmenu && <Submenu items={children} showSubmenu={showSubmenu} />}
      </li>
    )
  }
}

HeaderMenuLink.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string,
  url: PropTypes.string,
  newWindow: PropTypes.bool,
  children: PropTypes.arrayOf(
    PropTypes.shape({
      navItem: PropTypes.shape({
        type: PropTypes.string,
        customText: PropTypes.string,
        url: PropTypes.string,
        target: PropTypes.string,
        element: PropTypes.shape({
          id: PropTypes.string,
          title: PropTypes.string,
          uri: PropTypes.string,
        }),
      }),
    })
  ),
}

export default HeaderMenuLink

import React from "react"

const IconFacebook = () => {
  return (
    <svg className="w-full h-full fill-current" viewBox="0 0 24 24">
      <title>Facebook</title>
      <path d="M12 0C5.4 0 0 5.4 0 12s5.4 12 12 12 12-5.4 12-12S18.6 0 12 0zm3.1 12.5h-1.8V19h-2.7v-6.5H9.2v-2.2h1.3V8.9c0-1.8.8-2.9 2.9-2.9h1.8v2.3h-1.1c-.8 0-.9.3-.9.9v1.1h2l-.1 2.2z" />
    </svg>
  )
}

export default IconFacebook

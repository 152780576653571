import React, { useEffect, useState, useRef } from "react"
import TransitionLinkFade from "../TransitionLink/TransitionLinkFade"
import { useStaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"

import { BLUE, GREY, HOME_PAGE, PINK, YELLOW } from "../../constants"

import "./Header.css"
import Logo from "../SVG/Logo"
import HeaderMainMenu from "./HeaderMainMenu"
import HeaderMenuButton from "./HeaderMenuButton"
import FlyoutMenu from "../FlyoutMenu/FlyoutMenu"

const Header = ({ theme, currentPage }) => {
  const data = useStaticQuery(
    graphql`
      query {
        craftGeneralGlobalSet {
          linkPrimary
          linkSecondary
        }
        craftNavigationGlobalSet {
          mainMenu: headerNavigation {
            ... on Craft_headerNavigation_headerNavItem_BlockType {
              id
              primaryItem: headerPrimaryNav {
                type
                customText
                url
                target
                element {
                  ...linkFragment
                }
              }
              secondaryItems: headerSecondaryNavs {
                ... on Craft_headerSecondaryNavs_BlockType {
                  navItem {
                    type
                    customText
                    url
                    target
                    element {
                      ...linkFragment
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  )

  const { mainMenu } = data.craftNavigationGlobalSet
  const { linkPrimary, linkSecondary } = data.craftGeneralGlobalSet

  let headerClasses = null
  let buttonSecondaryClasses = null
  let bgHex = null

  switch (theme) {
    case BLUE:
      headerClasses = "bg-blue-500 text-white"
      buttonSecondaryClasses = "Button--primary-alt"
      bgHex = "#006F8D"
      break
    case PINK:
      headerClasses = "bg-pink-500 text-white"
      bgHex = "#FCABA1"
      break
    case YELLOW:
      headerClasses = "bg-yellow-500 text-white"
      bgHex = "#F5BC37"
      break
    case GREY:
      headerClasses = "bg-blue-200 text-charcoal-500"
      bgHex = "#F5F6FA"
      break
    default:
      headerClasses = "bg-blue-500 text-white"
      buttonSecondaryClasses = "Button--primary-alt"
      bgHex = "#006F8D"
      break
  }

  const previousScrollY = useRef(0)
  const [scrollingUp, setScrollingUp] = useState(true)
  const [isFlyoutMenuOpen, setIsFlyoutMenuOpen] = useState(false)
  const [homeButtonSecondaryClasses, setHomeButtonSecondaryClasses] =
    useState(null)
  const requestRef = useRef(null)
  let ticking = false

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true })
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  })

  const handleScroll = () => {
    if (!ticking) {
      requestRef.current = requestAnimationFrame(getScrollDirection)
      ticking = true
    }
  }

  const getScrollDirection = () => {
    const currentScrollYPosition = window.scrollY

    if (currentScrollYPosition > previousScrollY.current) {
      setScrollingUp(false)
    } else if (currentScrollYPosition < previousScrollY.current) {
      setScrollingUp(true)
    }

    if (currentPage === HOME_PAGE) {
      const heroHeight =
        document.getElementById("hero").getBoundingClientRect().height - 190 // height of search bar

      if (currentScrollYPosition >= heroHeight) {
        document.documentElement.style.setProperty("--home-header-theme", bgHex)
        setHomeButtonSecondaryClasses("Button--primary-alt")
      } else {
        document.documentElement.style.setProperty(
          "--home-header-theme",
          "transparent"
        )
        setHomeButtonSecondaryClasses(null)
      }
    }

    ticking = false
    previousScrollY.current =
      currentScrollYPosition <= 0 ? 0 : currentScrollYPosition
  }

  const toggleIsFlyoutMenuOpen = () => {
    setIsFlyoutMenuOpen(isFlyoutMenuOpenPrev => !isFlyoutMenuOpenPrev)
  }

  return (
    <header
      style={{
        top:
          !scrollingUp && !isFlyoutMenuOpen
            ? "calc(-1 * var(--site-header-height))"
            : "0px",
        transitionProperty: "top",
      }}
      className="h-shh z-50 inset-x-0 duration-300 sticky"
    >
      {currentPage === HOME_PAGE && (
        <div className="absolute inset-0 bg-gradient-to-b from-charcoal-500 via-charcoal-500 opacity-30 z-0"></div>
      )}
      <div
        className={
          currentPage === HOME_PAGE
            ? "relative z-10 bg-home-header-theme text-white transition-colors"
            : headerClasses
        }
      >
        <div className="js-header">
          <div className="px-ogs pb-7 pt-9 h-full flex justify-between relative z-10 lg:grid lg:grid-cols-12 lg:gap-x-gs items-center">
            <div className="col-span-2">
              {currentPage === HOME_PAGE ? (
                <div className="w-36 h-9 flex-shrink-0">
                  <Logo />
                </div>
              ) : (
                <TransitionLinkFade
                  className="w-36 h-9 flex-shrink-0 block hover:opacity-100"
                  to="/"
                >
                  <Logo />
                </TransitionLinkFade>
              )}
            </div>
            <div className="hidden lg:flex justify-center col-span-8">
              {mainMenu.length > 0 && <HeaderMainMenu menuItems={mainMenu} />}
            </div>
            <div className="lg:col-span-2 flex items-center space-x-10 lg:block">
              <HeaderMenuButton
                isActive={isFlyoutMenuOpen}
                onToggle={toggleIsFlyoutMenuOpen}
              />
              <div className="hidden lg:flex justify-end space-x-3 xl:space-x-5">
                {linkPrimary && (
                  <a
                    href={linkPrimary}
                    className="ButtonGhost ButtonGhost--primary ButtonGhost--no-border"
                  >
                    Login
                  </a>
                )}
                {linkSecondary && (
                  <a
                    href={linkSecondary}
                    className={`Button Button--primary ${
                      currentPage === HOME_PAGE && theme === BLUE
                        ? homeButtonSecondaryClasses
                        : buttonSecondaryClasses
                    }`}
                  >
                    Register
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {mainMenu.length > 0 && (
        <FlyoutMenu
          isOpen={isFlyoutMenuOpen}
          menuItems={mainMenu}
          linkPrimary={linkPrimary}
          linkSecondary={linkSecondary}
        />
      )}
    </header>
  )
}

Header.propTypes = {
  theme: PropTypes.string.isRequired,
}

export default Header

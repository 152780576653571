import * as React from "react"
import PropTypes from "prop-types"

import HeaderMenuLink from "./HeaderMenuLink"

const HeaderMainMenu = ({ menuItems }) => {
  return (
    <ul className="flex space-x-2 xl:space-x-8 text-sm xl:text-base">
      {menuItems.map(item => {
        const { primaryItem, secondaryItems } = item
        const { id, type, customText, target, element } = primaryItem
        const title = customText || element.title
        const url = !element ? primaryItem.url : element.uri
        const newWindow = !!target

        return (
          <HeaderMenuLink
            key={`${id} ${title}`}
            type={type}
            title={title}
            url={url}
            newWindow={newWindow}
            children={secondaryItems}
          />
        )
      })}
    </ul>
  )
}

HeaderMainMenu.propTypes = {
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      primaryItem: PropTypes.shape({
        type: PropTypes.string,
        customText: PropTypes.string,
        url: PropTypes.string,
        target: PropTypes.string,
        element: PropTypes.shape({
          id: PropTypes.string,
          title: PropTypes.string,
          uri: PropTypes.string,
        }),
      }),
      secondaryItems: PropTypes.arrayOf(
        PropTypes.shape({
          navItem: PropTypes.shape({
            type: PropTypes.string,
            customText: PropTypes.string,
            url: PropTypes.string,
            target: PropTypes.string,
            element: PropTypes.shape({
              id: PropTypes.string,
              title: PropTypes.string,
              uri: PropTypes.string,
            }),
          }),
        })
      ),
    })
  ),
}

export default HeaderMainMenu

import React from "react"

const IconLinkedIn = () => {
  return (
    <svg className="w-full h-full fill-current" viewBox="0 0 24 24">
      <title>LinkedIn</title>
      <path d="M12 0C5.4 0 0 5.4 0 12s5.4 12 12 12 12-5.4 12-12S18.6 0 12 0zM8.5 18.1H5.8V9.9h2.7v8.2zM7.1 8.8c-.9 0-1.5-.6-1.5-1.4 0-.8.6-1.4 1.5-1.4s1.5.6 1.5 1.4c.1.8-.5 1.4-1.5 1.4zm11.2 9.3h-2.7v-4.4c0-1.1-.4-1.8-1.4-1.8-.8 0-1.2.5-1.4 1-.1.2-.1.4-.1.7v4.6H10V10h2.7v1.2c.4-.6 1-1.3 2.5-1.3 1.8 0 3.1 1.2 3.1 3.7v4.5z" />
    </svg>
  )
}

export default IconLinkedIn

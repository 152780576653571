import React, { useState } from "react"
import PropTypes from "prop-types"

import FlyoutMenuLink from "./FlyoutMenuLink"

const FlyoutMenuItem = ({ item }) => {
  const [isOpen, setIsOpen] = useState(false)

  const { primaryItem, secondaryItems } = item
  const { type, customText, target, element } = primaryItem
  const title = customText || element.title
  const url = !element ? primaryItem.url : element.uri
  const newWindow = !!target
  const hasSubmenu = !!secondaryItems?.length

  const toggleIsOpen = () => {
    setIsOpen(isOpenPrev => !isOpenPrev)
  }
  return (
    <li className={`FlyoutMenuItem ${isOpen ? "FlyoutMenuItem--open" : ""}`}>
      <div className="FlyoutMenuItem__inner">
        <FlyoutMenuLink
          type={type}
          title={title}
          url={url}
          newWindow={newWindow}
        />

        {hasSubmenu && (
          <button className="FlyoutMenuItem__toggle" onClick={toggleIsOpen}>
            {!isOpen ? "+" : "-"}
          </button>
        )}
      </div>

      {hasSubmenu && isOpen && (
        <ul className="FlyoutMenuItems FlyoutMenuItems--sub">
          {secondaryItems.map(({ navItem }) => {
            const { type, customText, target, element } = navItem
            const title = customText || element.title
            const url = !element ? primaryItem.url : element.uri
            const newWindow = !!target

            return (
              <li className="FlyoutMenuItem">
                <div className="FlyoutMenuItem__inner">
                  <FlyoutMenuLink
                    type={type}
                    title={title}
                    url={url}
                    newWindow={newWindow}
                  />
                </div>
              </li>
            )
          })}
        </ul>
      )}
    </li>
  )
}

FlyoutMenuItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string,
    primaryItem: PropTypes.shape({
      type: PropTypes.string,
      customText: PropTypes.string,
      url: PropTypes.string,
      target: PropTypes.string,
      element: PropTypes.shape({
        id: PropTypes.string,
        title: PropTypes.string,
        uri: PropTypes.string,
      }),
    }),
    secondaryItems: PropTypes.arrayOf(
      PropTypes.shape({
        navItem: PropTypes.shape({
          type: PropTypes.string,
          customText: PropTypes.string,
          url: PropTypes.string,
          target: PropTypes.string,
          element: PropTypes.shape({
            id: PropTypes.string,
            title: PropTypes.string,
            uri: PropTypes.string,
          }),
        }),
      })
    ),
  }),
}

export default FlyoutMenuItem

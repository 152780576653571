import React from "react"

const Logo = () => {
  return (
    <svg className="fill-current w-full h-full" viewBox="0 0 150 36">
      <title>Frankie</title>
      <path d="M119.2 7.7c-.1-3.2-1.6-6-3.9-7.6-.2-.2-.5-.2-.7 0-1.1.7-2 1.7-2.6 2.9-.1.1-.1.3 0 .5 1.5 2.7 4.2 4.4 7.3 4.4 2.3 0 4.4-1 5.9-2.6.5-.5 1-1.2 1.4-1.8.1-.1.1-.3 0-.5-.7-1.2-1.6-2.2-2.6-2.9-.2-.2-.5-.2-.7 0-1.5 1.1-2.7 2.6-3.4 4.5-.5 1-.7 2-.7 3.1M5.5 6.6v9.6h13.2c.2 0 .4.2.4.4v4.6c0 .2-.2.4-.4.4H5.5V35c0 .2-.2.4-.4.4H.4c-.2 0-.4-.2-.4-.4V1.6c0-.2.2-.4.4-.4h18.8c.2 0 .4.2.4.4v4.6c0 .2-.2.4-.4.4H5.5zm30.4 4.3v4.9c0 .3-.2.5-.5.5-3.3-.1-6.9 1.6-6.9 6.9V35c0 .2-.2.4-.4.4h-4.4c-.2 0-.4-.2-.4-.4V11.4c0-.2.2-.4.4-.4h4.4c.2 0 .4.2.4.4v3.7c1.4-3.2 4.1-4.4 7-4.5.2-.1.4.1.4.3zm20.9 12.3c0-4.5-3.2-7.8-7.5-7.8s-7.5 3.3-7.5 7.8S45 31 49.3 31c4.2 0 7.5-3.3 7.5-7.8zm5.1-11.8V35c0 .2-.2.4-.4.4h-4.4c-.2 0-.4-.2-.4-.4v-3.1C55 34.4 52.1 36 48.4 36c-6.5 0-11.9-5.6-11.9-12.8 0-7.3 5.4-12.8 11.9-12.8 3.7 0 6.5 1.6 8.3 4.1v-3.1c0-.2.2-.4.4-.4h4.4c.2-.1.4.1.4.4zm26.5 9V35c0 .2-.2.4-.4.4h-4.4c-.2 0-.4-.2-.4-.4V21c0-3.7-2.1-5.7-5.3-5.7-3.4 0-6.1 2.1-6.1 7V35c0 .2-.2.4-.4.4H67c-.2 0-.4-.2-.4-.4V11.4c0-.2.2-.4.4-.4h4.4c.2 0 .4.2.4.4v2.7c1.6-2.5 4.1-3.8 7.4-3.8 5.4 0 9.2 3.7 9.2 10.1zm19.5 15l-9.8-11.3V35c0 .2-.2.4-.4.4h-4.4c-.2 0-.4-.2-.4-.4V1.6c0-.2.2-.4.4-.4h4.4c.2 0 .4.2.4.4v20.2l9.3-10.8h6.3l-10.5 12 10.9 12.5h-6.2zm9.1-24.5h4.4c.2 0 .4.2.4.4V35c0 .2-.2.4-.4.4H117c-.2 0-.4-.2-.4-.4V11.4c0-.3.2-.5.4-.5zm27.8 10.2c-.7-4.2-3.7-6-6.8-6-3.9 0-6.5 2.3-7.2 6h14zm-6.3 10.1c2.9 0 5-1.3 6.1-3l4.2 2.5C146.6 34 143 36 138.4 36c-7.9 0-13-5.5-13-12.8 0-7.2 5.1-12.8 12.6-12.8 7.1 0 12 5.9 12 12.9 0 .7-.1 1.5-.2 2.2h-19c.8 3.7 3.8 5.7 7.7 5.7z" />
    </svg>
  )
}

export default Logo

import React from "react"
import "./AlertBanner.css"

const AlertBanner = ({ alertContent }) => {
  return (
    <div
      className="alert-banner bg-yellow-500 text-black text-center p-2"
      dangerouslySetInnerHTML={{
        __html: alertContent,
      }}
    />
  )
}

export default AlertBanner

import React from "react"

const IconVimeo = () => {
  return (
    <svg className="w-full h-full fill-current" viewBox="0 0 24 24">
      <title>Vimeo</title>
      <path d="M12 0C5.4 0 0 5.4 0 12s5.4 12 12 12 12-5.4 12-12S18.6 0 12 0zm3.9 15.4c-2 2.5-3.6 3.8-5 3.8-.8 0-1.6-.8-2.1-2.3-.2-.6-.3-1.1-.5-1.7-.2-.9-.5-1.7-.7-2.6-.4-1.5-.9-2.3-1.4-2.3-.1 0-.5.2-1.1.7l-.7-.9c.7-.6 1.4-1.2 2.1-1.9C7.4 7.5 8.2 7 8.6 7c1.1-.1 1.8.7 2.1 2.3.3 1.8.5 2.9.6 3.3.3 1.5.7 2.2 1.1 2.2.3 0 .8-.5 1.4-1.4.6-1 .9-1.7 1-2.2.1-.8-.2-1.2-1-1.2-.3 0-.7.1-1.1.2.7-2.3 2.1-3.4 4.1-3.4 1.5 0 2.2 1 2.1 2.9-.2 1.4-1.1 3.3-3 5.7z" />
    </svg>
  )
}

export default IconVimeo

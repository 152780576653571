import React from "react"

const IconTwitter = () => {
  return (
    <svg className="w-full h-full fill-current" viewBox="0 0 24 24">
      <title>Twitter</title>
      <path d="M12 0C5.4 0 0 5.4 0 12s5.4 12 12 12 12-5.4 12-12S18.6 0 12 0zm5.6 8.5c-.1.1-.3.4-.6.7-.5.5-.5.5-.5.9 0 .6-.3 1.9-.6 2.5-.5 1.3-1.7 2.6-2.8 3.3-1.6.9-3.7 1.2-5.5.6-.6-.2-1.6-.7-1.6-.7s.3-.1.7-.1c.8 0 1.6-.2 2.2-.6l.5-.3-.5-.2c-.7-.3-1.4-.8-1.6-1.4-.1-.2 0-.2.4-.2h.5l-.4-.2c-.5-.2-.9-.7-1.1-1.1-.2-.3-.4-1.1-.3-1.1 0 0 .2 0 .4.1.6.2.7.2.3-.2-.7-.6-.8-1.6-.5-2.5l.1-.4.6.6c1.1 1.1 2.5 1.8 4 2l.4.1v-.4c-.1-1.1.6-2.1 1.6-2.4.4-.1 1-.2 1.5 0 .2.1.5.2.7.4l.4.3.5-.1c.3-.1.6-.2.7-.3.1-.1.3-.1.3-.1 0 .1-.3.6-.6.9-.4.4-.3.4.5.2.4-.5.4-.5.3-.3z" />
    </svg>
  )
}

export default IconTwitter

import React from "react"

const HeaderMenuButton = ({ isActive, onToggle }) => {
  return (
    <button
      onClick={onToggle}
      className={`HeaderMenuButton ${
        isActive ? "HeaderMenuButton--active" : ""
      }`}
    >
      <span className="HeaderMenuButton__text">Menu</span>
      <span className="HeaderMenuButton__lines">
        <span className="HeaderMenuButton__line"></span>
        <span className="HeaderMenuButton__line"></span>
      </span>
    </button>
  )
}

export default HeaderMenuButton

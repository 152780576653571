import * as React from "react"
import TransitionLinkFade from "../TransitionLink/TransitionLinkFade"
import PropTypes from "prop-types"

import { LINK_TYPE_ENTRY } from "../../constants"

const FooterMenuSecondary = ({ menuItems }) => {
  return (
    <div className="col-span-2 md:col-span-4 md:row-start-4 md:col-start-3 flex justify-end md:justify-start space-x-gs md:space-x-8">
      {menuItems.map(item => {
        const { id, navItem } = item
        const { type, customText, target, element } = navItem
        const title = customText || element?.title || ""
        const url = !element ? navItem.url : element.uri
        const newWindow = !!target

        if (type !== LINK_TYPE_ENTRY) {
          /*eslint-disable*/
          return (
            <a
              key={id + url}
              href={url}
              target={newWindow ? "_blank" : null}
              rel={newWindow ? "noopener noreferrer" : null}
            >
              {title}
            </a>
          )
          /*eslint-enable*/
        } else {
          return (
            <TransitionLinkFade key={id + url} to={`/${url}`}>
              {title}
            </TransitionLinkFade>
          )
        }
      })}
    </div>
  )
}

FooterMenuSecondary.propTypes = {
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      navItem: PropTypes.shape({
        type: PropTypes.string,
        customText: PropTypes.string,
        url: PropTypes.string,
        target: PropTypes.string,
        element: PropTypes.shape({
          id: PropTypes.string,
          title: PropTypes.string,
          uri: PropTypes.string,
        }),
      }),
    })
  ),
}

export default FooterMenuSecondary

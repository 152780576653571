import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "../Header/Header"
import Footer from "../Footer/Footer"
// import HelperGrid from "../HelperGrid"
import Seo from "../Seo"
import AlertBanner from "../AlertBanner/AlertBanner"

const Layout = ({ children, title, description, theme, location }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
      craftGeneralGlobalSet {
        alertBanner
      }
    }
  `)

  const currentPage = location.pathname
  const { site, craftGeneralGlobalSet } = data

  return (
    <div className="bg-blue-200">
      {craftGeneralGlobalSet?.alertBanner && (
        <AlertBanner alertContent={craftGeneralGlobalSet.alertBanner} />
      )}
      <Header theme={theme} currentPage={currentPage} />
      <Seo title={title} description={description} />
      {/* <HelperGrid /> */}
      <main>{children}</main>
      <Footer siteTitle={site.siteMetadata?.title} />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  theme: PropTypes.string.isRequired,
  location: PropTypes.object,
}

Layout.defaultProps = {
  location: {
    pathname: "",
  },
}

export default Layout

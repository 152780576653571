import React from "react"
import PropTypes from "prop-types"

import FlyoutMenuItem from "./FlyoutMenuItem"

const FlyoutMenuItems = ({ items }) => {
  return (
    <ul className="FlyoutMenuItems">
      {items.map(item => {
        return <FlyoutMenuItem key={item.id} item={item} />
      })}
    </ul>
  )
}

FlyoutMenuItems.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      primaryItem: PropTypes.shape({
        type: PropTypes.string,
        customText: PropTypes.string,
        url: PropTypes.string,
        target: PropTypes.string,
        element: PropTypes.shape({
          id: PropTypes.string,
          title: PropTypes.string,
          uri: PropTypes.string,
        }),
      }),
      secondaryItems: PropTypes.arrayOf(
        PropTypes.shape({
          navItem: PropTypes.shape({
            type: PropTypes.string,
            customText: PropTypes.string,
            url: PropTypes.string,
            target: PropTypes.string,
            element: PropTypes.shape({
              id: PropTypes.string,
              title: PropTypes.string,
              uri: PropTypes.string,
            }),
          }),
        })
      ),
    })
  ),
}

export default FlyoutMenuItems

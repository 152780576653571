import * as React from "react"
import TransitionLinkFade from "../TransitionLink/TransitionLinkFade"
import PropTypes from "prop-types"

import { LINK_TYPE_ENTRY } from "../../constants"

const FooterMenuPrimary = ({ menuItems }) => {
  return (
    <React.Fragment>
      {menuItems.map(item => {
        const { primaryItem, secondaryItems } = item
        const { id, type, customText, target, element } = primaryItem
        const title = customText || element.title
        const url = !element ? primaryItem.url : element.uri
        const newWindow = !!target

        return (
          <div key={id + url} className="col-span-1 mb-12 xl:mb-0">
            {/*eslint-disable*/}
            {type !== LINK_TYPE_ENTRY ? (
              <a
                target={newWindow ? "_blank" : null}
                rel={newWindow ? "noopener noreferrer" : null}
                href={url}
                className="inline-block"
              >
                <h3 className="Footer-heading">{title}</h3>
              </a>
            ) : (
              <TransitionLinkFade to={`/${url}`}>
                <h3 className="Footer-heading">{title}</h3>
              </TransitionLinkFade>
            )}
            {/*eslint-enable*/}
            {secondaryItems.length > 0 && (
              <ul className="space-y-3">
                {secondaryItems.map(item => {
                  const { id, navItem } = item
                  const { type, customText, target, element } = navItem
                  const title = customText || element.title
                  const url = !element ? primaryItem.url : element.uri
                  const newWindow = !!target

                  if (type !== LINK_TYPE_ENTRY) {
                    return (
                      <li key={id + url}>
                        {/*eslint-disable*/}
                        <a
                          target={newWindow ? "_blank" : null}
                          rel={newWindow ? "noopener noreferrer" : null}
                          href={url}
                        >
                          {title}
                        </a>
                        {/*eslint-enable*/}
                      </li>
                    )
                  } else {
                    return (
                      <li key={id + title}>
                        <TransitionLinkFade to={`/${url}`}>
                          {title}
                        </TransitionLinkFade>
                      </li>
                    )
                  }
                })}
              </ul>
            )}
          </div>
        )
      })}
    </React.Fragment>
  )
}

FooterMenuPrimary.propTypes = {
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      primaryItem: PropTypes.shape({
        type: PropTypes.string,
        customText: PropTypes.string,
        url: PropTypes.string,
        target: PropTypes.string,
        element: PropTypes.shape({
          id: PropTypes.string,
          title: PropTypes.string,
          uri: PropTypes.string,
        }),
      }),
      secondaryItems: PropTypes.arrayOf(
        PropTypes.shape({
          navItem: PropTypes.shape({
            type: PropTypes.string,
            customText: PropTypes.string,
            url: PropTypes.string,
            target: PropTypes.string,
            element: PropTypes.shape({
              id: PropTypes.string,
              title: PropTypes.string,
              uri: PropTypes.string,
            }),
          }),
        })
      ),
    })
  ),
}

export default FooterMenuPrimary

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import "./Footer.css"
import FooterMenuPrimary from "./FooterMenuPrimary"
import FooterMenuSecondary from "./FooterMenuSecondary"
import SocialLinks from "../SocialLinks/SocialLinks"
import LogoMark from "../SVG/Logomark"
// import IconBCorp from "../SVG/IconBCorp"

const Footer = ({ siteTitle }) => {
  const data = useStaticQuery(
    graphql`
      query {
        craftGeneralGlobalSet {
          acknowledgement
        }
        craftNavigationGlobalSet {
          menuPrimary: footerPrimaryNavigation {
            ... on Craft_footerPrimaryNavigation_navItem_BlockType {
              id
              primaryItem: primaryFooterPrimaryNav {
                type
                customText
                url
                target
                element {
                  ...linkFragment
                }
              }
              secondaryItems: primaryFooterSecondaryNavs {
                ... on Craft_primaryFooterSecondaryNavs_BlockType {
                  id
                  navItem {
                    type
                    customText
                    url
                    target
                    element {
                      ...linkFragment
                    }
                  }
                }
              }
            }
          }
          menuSecondary: footerSecondaryNavigation {
            ... on Craft_footerSecondaryNavigation_BlockType {
              id
              navItem {
                type
                customText
                url
                target
                element {
                  ...linkFragment
                }
              }
            }
          }
        }
      }
    `
  )

  const globals = data.craftGeneralGlobalSet
  const { menuPrimary, menuSecondary } = data.craftNavigationGlobalSet

  return (
    <footer className="text-white bg-blue-500 text-sm font-semibold px-ogs pt-20 pb-6 md:pb-16">
      <div className="grid grid-cols-4 md:grid-cols-12 gap-x-gs">
        <div className="col-span-4 md:col-span-2 w-15 h-8 mb-15 xl:mb-0">
          <LogoMark />
        </div>

        <div className="col-span-4 md:col-span-12 xl:col-span-10 grid gap-x-gs grid-cols-2 md:grid-cols-3 lg:grid-cols-6 lg:pb-40 border-b">
          {menuPrimary.length > 0 && (
            <FooterMenuPrimary menuItems={menuPrimary} />
          )}
        </div>

        <div className="col-span-4 md:col-span-6 xl:col-span-4 xl:col-start-3 md:row-start-3 pt-6 md:pt-8 pb-4 md:pb-15">
          {globals.acknowledgement != null && (
            <div className="text-xs">{globals.acknowledgement}</div>
          )}
        </div>
        <div className="col-start-4 md:col-start-12 md:row-start-3 mb-10 md:mb-16 md:pt-8">
          {/* <div className="ml-auto w-15 h-24">
            <IconBCorp />
          </div> */}
        </div>

        <div className="col-span-3 row-start-4 md:col-start-10 mb-10 md:flex md:justify-end md:mb-0">
          <SocialLinks />
        </div>
        <div className="col-span-2 md:row-start-4 text-sm">
          &copy; {new Date().getFullYear()} {siteTitle}
        </div>
        {menuSecondary.length > 0 && (
          <FooterMenuSecondary menuItems={menuSecondary} />
        )}
      </div>
    </footer>
  )
}

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer

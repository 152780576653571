/* GENERAL */
export const EMPTY_CATEGORY_DEFAULT = "All"
export const LINK_TYPE_ENTRY = "entry"

/* Screen Widths */
export const SCREEN_WIDTH_LG = 1024
export const SCREEN_WIDTH_XL = 1280
export const SCREEN_WIDTH_3XL = 1920

/* Routes */
export const HOME_PAGE = "/"

/* Brand Colours */
export const PINK = "pink"
export const BLUE = "blue"
export const YELLOW = "yellow"
export const WHITE = "white"
export const GREY = "grey"
export const NONE = "none"
export const BLACK = "black"
export const TRANSPARENT = "transparent"

/* PAGE TIERS */
export const TIER_ONE = "tier1"
export const TIER_TWO = "tier2"
export const TIER_THREE = "tier3"

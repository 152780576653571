import React from "react"
import PropTypes from "prop-types"
import TransitionLinkFade from "../TransitionLink/TransitionLinkFade"

import { LINK_TYPE_ENTRY } from "../../constants"

const HeaderSubmenu = ({ submenuItems }) => {
  return (
    <div className="HeaderSubmenu transform-gpu">
      <div className="HeaderSubmenu__arrow"></div>
      <ul className="HeaderSubmenu__inner">
        {submenuItems.map(item => {
          const { id, navItem } = item
          const { type, customText, target, element } = navItem
          const title = customText || element.title
          const url = !element ? navItem.url : element.uri
          const newWindow = !!target

          if (type !== LINK_TYPE_ENTRY) {
            return (
              <li key={`${id} ${url}`}>
                {/*eslint-disable*/}
                <a
                  target={newWindow ? "_blank" : null}
                  rel={newWindow ? "noopener noreferrer" : null}
                  href={url}
                  className="HeaderSubmenu__link"
                >
                  {title}
                </a>
                {/*eslint-enable*/}
              </li>
            )
          } else {
            return (
              <li key={`${id} ${url}`}>
                <TransitionLinkFade
                  to={`/${url}`}
                  activeClassName="HeaderSubmenu__link--active"
                  className="HeaderSubmenu__link"
                >
                  {title}
                </TransitionLinkFade>
              </li>
            )
          }
        })}
      </ul>
    </div>
  )
}

HeaderSubmenu.propTypes = {
  submenuItems: PropTypes.arrayOf(
    PropTypes.shape({
      navItem: PropTypes.shape({
        type: PropTypes.string,
        customText: PropTypes.string,
        url: PropTypes.string,
        target: PropTypes.string,
        element: PropTypes.shape({
          id: PropTypes.string,
          title: PropTypes.string,
          uri: PropTypes.string,
        }),
      }),
    })
  ),
}

export default HeaderSubmenu

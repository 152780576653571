import React, { useEffect, useRef } from "react"
import {
  clearAllBodyScrollLocks,
  disableBodyScroll,
  enableBodyScroll,
} from "body-scroll-lock"
import { CSSTransition } from "react-transition-group"
import PropTypes from "prop-types"

import "./FlyoutMenu.css"
import FlyoutMenuItems from "./FlyoutMenuItems"
import SocialLinks from "../SocialLinks/SocialLinks"

const FlyoutMenu = ({ isOpen, menuItems, linkPrimary, linkSecondary }) => {
  const scrollRef = useRef()

  useEffect(() => {
    const currentScrollRef = scrollRef.current
    if (!currentScrollRef) return clearAllBodyScrollLocks()
    if (isOpen) {
      disableBodyScroll(currentScrollRef)
    } else {
      enableBodyScroll(currentScrollRef)
    }
  }, [isOpen])

  return (
    <CSSTransition in={isOpen} timeout={500} classNames="fade" unmountOnExit>
      <nav className="flex flex-col bg-blue-500 pt-shh fixed inset-0 text-white lg:hidden">
        <div className="overflow-y-auto" ref={scrollRef}>
          <FlyoutMenuItems items={menuItems} />
        </div>

        <div className="flex-1 flex flex-col px-6 py-8">
          <div className="flex gap-x-3 mb-4">
            {linkPrimary && (
              <a
                href={linkPrimary}
                className="ButtonGhost ButtonGhost--primary ButtonGhost--no-border"
              >
                Login
              </a>
            )}

            {linkSecondary && (
              <a
                href={linkSecondary}
                className="Button Button--primary Button--primary-alt"
              >
                Register
              </a>
            )}
          </div>

          <div className="mt-auto">
            <SocialLinks />
          </div>
        </div>
      </nav>
    </CSSTransition>
  )
}

FlyoutMenu.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      primaryItem: PropTypes.shape({
        type: PropTypes.string,
        customText: PropTypes.string,
        url: PropTypes.string,
        target: PropTypes.string,
        element: PropTypes.shape({
          id: PropTypes.string,
          title: PropTypes.string,
          uri: PropTypes.string,
        }),
      }),
      secondaryItems: PropTypes.arrayOf(
        PropTypes.shape({
          navItem: PropTypes.shape({
            type: PropTypes.string,
            customText: PropTypes.string,
            url: PropTypes.string,
            target: PropTypes.string,
            element: PropTypes.shape({
              id: PropTypes.string,
              title: PropTypes.string,
              uri: PropTypes.string,
            }),
          }),
        })
      ),
    })
  ),
  linkPrimary: PropTypes.string,
  linkSecondary: PropTypes.string,
}

export default FlyoutMenu

import React from "react"

const Logomark = () => {
  return (
    <svg className="h-full fill-current" viewBox="0 0 59 32">
      <path d="M29.4427 30.8387C29.0508 18.1044 22.8883 6.92771 13.6988.466433c-.8756-.616176-2.0347-.616176-2.9103 0C6.52732 3.47029 2.89986 7.46687.231405 12.1823c-.3085401.5477-.3085401 1.2666 0 1.8143C6.32716 24.6513 17.1595 31.7459 29.4927 31.7459c9.2229 0 17.6118-3.9709 23.7826-10.3809 2.1098-2.1994 3.9443-4.6469 5.487-7.3598.3169-.5477.3169-1.2752 0-1.8229C56.0939 7.46687 52.4665 3.4275 48.2136.457875c-.8756-.616176-2.0347-.607618-2.9103.008558C39.2409 4.71975 34.4544 11.0527 31.8443 18.5409c-1.3676 3.8254-2.1514 8.0017-2.3015 12.2893" />
    </svg>
  )
}

export default Logomark

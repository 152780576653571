import React from "react"

const IconInstagram = () => {
  return (
    <svg className="w-full h-full fill-current" viewBox="0 0 24 24">
      <title>Instagram</title>
      <path d="M14.1 12c0 1.2-1 2.1-2.1 2.1-1.2 0-2.1-1-2.1-2.1 0-1.2 1-2.1 2.1-2.1 1.2 0 2.1.9 2.1 2.1zm3.1-2.6v5.2c0 .6-.1 1-.2 1.2-.1.3-.3.5-.5.7-.2.2-.4.4-.7.5-.2.1-.6.2-1.2.2H9.4c-.6 0-1-.1-1.2-.2-.3-.1-.5-.3-.7-.5s-.4-.4-.5-.7c-.1-.2-.2-.6-.2-1.2V12 9.4c0-.6.1-1 .2-1.2.1-.3.3-.5.5-.7s.4-.4.7-.5c.2-.1.6-.2 1.2-.2h5.2c.6 0 1 .1 1.2.2.3.1.5.3.7.5.2.2.4.4.5.7.1.2.2.6.2 1.2zM15.3 12c0-1.8-1.5-3.3-3.3-3.3-1.8 0-3.3 1.5-3.3 3.3 0 1.8 1.5 3.3 3.3 3.3 1.8 0 3.3-1.5 3.3-3.3zm.9-3.4c0-.4-.3-.8-.8-.8-.4 0-.8.3-.8.8 0 .4.3.8.8.8.4 0 .8-.4.8-.8zM24 12c0 6.6-5.4 12-12 12S0 18.6 0 12 5.4 0 12 0s12 5.4 12 12zm-5.6 0V9.4c0-.7-.1-1.1-.3-1.6-.2-.4-.4-.8-.7-1.1-.4-.4-.7-.6-1.1-.7-.4-.2-.9-.3-1.6-.3H9.5c-.7 0-1.1.1-1.6.3-.5.1-.9.3-1.2.7-.4.3-.6.7-.8 1.1-.1.4-.2.9-.3 1.6v5.2c0 .7.1 1.1.3 1.6.2.4.4.8.7 1.1.4.4.7.6 1.1.7.4.2.9.3 1.6.3h5.2c.7 0 1.1-.1 1.6-.3.4-.2.8-.4 1.1-.7.4-.4.6-.7.7-1.1.2-.4.3-.9.3-1.6.2-.6.2-.9.2-2.6z" />
    </svg>
  )
}

export default IconInstagram

import React from "react"
import TransitionLink from "gatsby-plugin-transition-link"
import gsap from "gsap"

import { BLUE, PINK, YELLOW } from "../../constants"

const exitAnimation = ({ exit: { length }, node }) => {
  const target = node.querySelector(".js-transition-link-target")

  gsap.fromTo(
    target,
    {
      opacity: 1,
    },
    {
      opacity: 0,
      duration: length,
    }
  )
}

const entryAnimation = ({ entry: { length }, node }) => {
  const target = node.querySelector(".js-transition-link-target")
  const targetHeaderBackground = target.dataset.theme
  const header = document.querySelector(".js-header")

  function getBackgroundColour(colour) {
    switch (colour) {
      case BLUE:
        return "#006F8D"

      case PINK:
        return "#FCABA1"

      case YELLOW:
        return "#F5BC37"

      default:
        return "transparent"
    }
  }

  const targetBgHex = getBackgroundColour(targetHeaderBackground)

  gsap.fromTo(
    target,
    {
      opacity: 0,
    },
    {
      opacity: 1,
      duration: length,
    }
  )

  gsap.fromTo(
    header,
    {
      backgroundColor: "transparent",
    },
    {
      backgroundColor: targetBgHex,
      duration: length,
    }
  )
}

const TransitionLinkFade = ({
  to,
  children,
  className,
  partiallyActive,
  activeClassName,
}) => {
  const httpIndex = to.indexOf("http")

  // Use <a> tags if link is external.
  if (httpIndex >= 0) {
    return (
      <a
        href={to.substring(httpIndex)}
        target="_blank"
        rel="noopener noreferrer"
        className={className}
      >
        {children}
      </a>
    )
  }

  return (
    <TransitionLink
      to={to}
      className={className}
      activeClassName={activeClassName}
      partiallyActive={partiallyActive}
      exit={{
        length: 0.5,
        trigger: ({ exit, node }) => exitAnimation({ exit, node }),
      }}
      entry={{
        length: 0.5,
        trigger: ({ entry, node }) => entryAnimation({ entry, node }),
      }}
    >
      {children}
    </TransitionLink>
  )
}

export default TransitionLinkFade

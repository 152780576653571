import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"

import "./SocialLinks.css"
import IconFacebook from "../SVG/IconFacebook"
import IconVimeo from "../SVG/IconVimeo"
import IconInstagram from "../SVG/IconInstagram"
import IconTwitter from "../SVG/IconTwitter"
import IconLinkedIn from "../SVG/IconLinkedIn"

const SocialLinks = () => {
  const data = useStaticQuery(
    graphql`
      query {
        craftGeneralGlobalSet {
          linkedinHandle
          vimeoLink
          facebookLink
          instagramHandle
          twitterHandle
        }
      }
    `
  )

  const {
    linkedinHandle,
    vimeoLink,
    facebookLink,
    instagramHandle,
    twitterHandle,
  } = data.craftGeneralGlobalSet

  return (
    <div className="space-x-5 flex items-center h-full">
      {facebookLink && (
        <a
          href={facebookLink}
          target="_blank"
          rel="noopener noreferrer"
          className="SocialLinks-link"
        >
          <IconFacebook />
        </a>
      )}
      {twitterHandle && (
        <a
          href={`https://www.twitter.com/${twitterHandle}`}
          target="_blank"
          rel="noopener noreferrer"
          className="SocialLinks-link"
        >
          <IconTwitter />
        </a>
      )}
      {vimeoLink && (
        <a
          href={vimeoLink}
          target="_blank"
          rel="noopener noreferrer"
          className="SocialLinks-link"
        >
          <IconVimeo />
        </a>
      )}
      {instagramHandle && (
        <a
          href={`https://www.instagram.com/${instagramHandle}`}
          target="_blank"
          rel="noopener noreferrer"
          className="SocialLinks-link"
        >
          <IconInstagram />
        </a>
      )}
      {linkedinHandle && (
        <a
          href={`https://www.linkedin.com/company/${linkedinHandle}`}
          target="_blank"
          rel="noopener noreferrer"
          className="SocialLinks-link"
        >
          <IconLinkedIn />
        </a>
      )}
    </div>
  )
}

export default SocialLinks
